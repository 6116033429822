
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.page {
  &__title {
    margin: 0 0 20px 0;
  }
  &_feedback-create {
    padding: 32px;
    background: #fff;
    border-radius: 16px;
  }
}
